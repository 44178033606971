import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import SendEmail from './sendEmail';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #100E09;
`;

const ContactHeader = styled.h1`
  font-size: 2rem;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin: 50px;

  svg {
    font-size: 24px;
    margin: 10px;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: #1da1f2; /* Twitter blue color */
    }
  }
`;

const Contact = () => {
  return (
    <ContactContainer>
      <ContactHeader>Contact Me At</ContactHeader>
      <SocialIconsContainer>
        <FaTwitter />
        <FaLinkedin />
      </SocialIconsContainer>
      <SendEmail />
    </ContactContainer>
  );
};

export default Contact;
