import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';

const SendEmailForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  margin-top: 30px;
`;

const FormField = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormTextArea = styled.textarea`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  background-color: #1da1f2; /* Twitter blue color */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0c87c6; /* Slightly darker shade on hover */
  }
`;

const SendEmail = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs.send('service_5yaq7fa', 'template_jhpp0b9', templateParams, '-lUTKJyO_wafdWVnb')
      .then((result) => {
        console.log(result.text);
        setName('');
        setEmail('');
        setMessage('');
        setSending(false);
      }, (error) => {
        console.log(error.text);
        setSending(false);
      });
  };

  return (
    <SendEmailForm onSubmit={handleFormSubmit}>
      <FormField
        type="text"
        name="user_name"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <FormField
        type="email"
        name="user_email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormTextArea
        name="message"
        placeholder="Message"
        rows="4"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <FormButton type="submit" disabled={sending}>
        {sending ? 'Sending...' : 'Send'}
      </FormButton>
    </SendEmailForm>
  );
};

export default SendEmail;
