import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Header from './components/Header'; // Import the Header component
import Footer from './components/Footer'; // Import the Footer component

const AppRouter = () => {
  return (
    <Router>
      <Header /> {/* Add the Header component */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer /> {/* Add the Footer component */}
    </Router>
  );
};

export default AppRouter;
