// components/Home.js
import React from 'react';
import styled from 'styled-components';
import ProfileImage from '../profile-picture.png';

const HomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(90deg, #100E09 40%, white 40%); /* Default background */

  @media (max-width: 768px) {
    flex-direction: column; /* Change flex-direction to column on mobile devices */
    background: linear-gradient(180deg, #100E09 30%, white 30%); /* Mobile background */
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  color: white;
  text-align: center;
  height: 60vh;
  background-color: #151922;
  padding: 20px;
  margin-left: 0%;
  box-shadow: 0 0 15px rgba(2, 4, 3, 0.6); /* Add a shadow to create a parallax effect */

  @media (max-width: 768px) {
    width: 60%;
    margin-left: 0;
    height: 50vh;
    margin-top: 20px;
  }
`;

const ProfilePicture = styled.img`
margin: 20px;
  width: 275px;
  height: 275px;
  border-radius: 50%;

  @media (max-width: 768px) {
    /* Styles for mobile devices */
    width: 150px;
    height: 150px;
  }
`;

const Title1 = styled.h1`
  font-size: 2.5rem;
  margin: 16px;

  @media (max-width: 768px) {
    /* Styles for mobile devices */
    font-size: 1.5rem;
  }
`;

const Title = styled.h2`
  font-size: 1.0rem;
  margin: 16px;

  @media (max-width: 768px) {
    /* Styles for mobile devices */
    font-size: 1rem;
  }
`;

const CustomH1 = styled.h3`
  font-size: 5rem; /* Set the desired font size for h1 */
  margin: 16px;

  @media (max-width: 768px) {
    font-size: 2rem; /* Adjust font size for mobile */
  }
`;

const CustomH2 = styled.h4`
  font-size: 2rem; /* Set the desired font size for h2 */
  margin: 16px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }
`;

const CustomP = styled.p`
  font-size: 1.2rem; /* Set the desired font size for p */
  margin: 16px;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Adjust font size for mobile */
  }
`;


const TransparentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Space the text evenly within the box */
  width: 30%;
  background-color: rgba(255, 255, 255, 0.0);
  padding: 20px;
  border-radius: 8px;
  color: black;
  height: 60vh;
  margin-left: 2%;
  text-align: justify;

  @media (max-width: 768px) {
    width: 80%;
    margin-left: 0;
    height: 50vh;
  }
`;

const Home = () => {
  return (
    <HomeContainer>
      <ContentContainer>
      <ProfilePicture src={ProfileImage}/>
        <Title1>Hemanth Vejandla</Title1>
        <Title>SECURITY CONSULTANT | PRIVACY RESEARCHER</Title>
      </ContentContainer>
      <TransparentBox>
        <CustomH1>Hello</CustomH1>
        <CustomH2>Here's who I am & what I do</CustomH2>
        <CustomP>24 year old Information Security Consultant with a demonstrated history in Web Development, 
        DevSec Ops and Business Management. Strong engineering professional with a Bachelor's degree focused in 
        Information Technology and Cyber Security from Macquarie University, with a continuing research in "Implications
         of Privacy on national security in relation to cyber warfare".</CustomP>
      </TransparentBox>
    </HomeContainer>
  );
};

export default Home;
