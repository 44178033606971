// components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
  background-color: #100E09;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  width: 100%;
  box-shadow: 0px -2px 4px rgba(2, 4, 3, 0.5); /* Add the shadow for parallax effect */

  @media (max-width: 768px) {
    /* Add mobile-specific styles here if needed */
  }
`;

const Copyright = styled.p`
  color: white;
  margin: 0;

  @media (max-width: 768px) {
    /* Add mobile-specific styles here if needed */
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;

  @media (max-width: 768px) {
    /* Add mobile-specific styles here if needed */
  }
`;

const SocialLink = styled.a`
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 16px;
  transition: transform 0.2s ease;

  &:first-child {
    margin-left: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: white;
  }

  &:hover svg path {
    fill: ${props => (props.isTwitter ? '#1DA1F2' : '#0077B5')};
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <Copyright>© Hemanth Vejandla {currentYear}</Copyright>
      <SocialLinks>
        <SocialLink
          href="https://twitter.com/HemantVejandla"
          target="_blank"
          rel="noopener noreferrer"
          isTwitter
        >
          <FontAwesomeIcon icon={faTwitter} />
        </SocialLink>
        <SocialLink
          href="https://www.linkedin.com/in/hemanth-vejandla/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialLink>
      </SocialLinks>
    </FooterContainer>
  );
};

export default Footer;
