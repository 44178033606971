import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from React Router
import logo from '../logo.svg';

const HeaderContainer = styled.header`
  background-color: #100E09;
  padding: 16px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  color: white;
  top: 0;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(2, 4, 3, 0.5); /* Add the shadow for parallax effect */

  @media (max-width: 768px) {
    /* Add mobile-specific styles here if needed */
  }
`;

const Logo = styled.img`
  width: 50px; /* Set the desired width for the logo */
  height: 50px; /* Set the desired height for the logo */
`;

const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-left: 20px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:first-child {
    margin-left: 0;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    color: #1da1f2;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <NavLink to="/"> {/* Link to the Home page */}
        <Logo src={logo} alt="Logo" />
      </NavLink>
      <NavMenu>
        <NavItem>
          <NavLink to="/about">About</NavLink> {/* Link to the About page */}
        </NavItem>
        <NavItem>
          <NavLink to="/contact">Contact</NavLink> {/* Link to the Contact page */}
        </NavItem>
      </NavMenu>
    </HeaderContainer>
  );
};

export default Header;
